<template>
  <section>
    <div
      v-for="item in props.items"
      :key="item.id"
    >
      <slot
        v-if="item.id === props.active"
        :name="item.id"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { TabId, UiTabItem } from '../UiTabs.types'

interface UiTabItemProps {
  items: UiTabItem[]
  active: TabId
  tag?: string | 'div'
}
const props = withDefaults(defineProps<UiTabItemProps>(), {
  tag: 'div',
})
</script>
